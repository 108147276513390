<style>
    .poptipContent .ivu-poptip-rel{
        width: 100%;
    }
    .stationFormItem .ivu-form-item-label:before {
        content: '*';
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        color: #ed4014;
    }
</style>
<template>
    <Layout>
        <Header class="header">
            <Breadcrumb class="breadcrumb">
                <BreadcrumbItem to="/sys_manage/threshold">阈值预警设置</BreadcrumbItem>
                <BreadcrumbItem>添加</BreadcrumbItem>
            </Breadcrumb>
        </Header>
        <Content class="content">
            <GeminiScrollbar style="height:100%;overflow: auto;">
            <Form ref="dataForm" :model="formData" :rules="rules" :label-width="200">
                <FormItem class="formItemWidth" label="阈值类型" prop="thrType">
                    <Select v-model="formData.thrType" style="width: 200px;" @on-change="changethrType">
                        <Option v-for="(item, index) in thrList" :key="index" :value="item.thrType">{{item.text}}</Option>
                    </Select>
                </FormItem>
                <FormItem v-if="formData.thrType === 2" class="formItemWidth" label="预报时间" prop="timeStr">
                    <Select v-model="formData.month" style="width: 200px;" placeholder="请选择月份">
                        <Option v-for="(item, index) in monthList" :key="index" :value="item.month">{{item.month}}</Option>
                    </Select>
                    <Select v-model="formData.tenDay" style="width: 200px;" placeholder="请选择旬">
                        <Option v-for="(item, index) in tenDayList" :key="index" :value="item.tenDay">{{item.tenDay}}</Option>
                    </Select>
                </FormItem>
                <FormItem class="formItemWidth stationFormItem" label='站点选择'>
                    <!-- <template>
                        <Poptip class="poptipContent" placement="bottom-start">
                            <Input class="poptipInput" v-model="stationList" type="textarea"/>
                            <div class="api" slot="content">
                                <stationSelect @checkedStation='checkedStation' v-if="stationArray.length>0" :areaList='stationArray'></stationSelect>
                            </div>
                        </Poptip>
                    </template> -->
                    <!-- <Input v-model="stationList" type="textarea" @on-focus="openStation()"/> -->
                    <div style="padding: 0 7px;min-height: 30px;width: 450px;border: 1px solid #0077EE;border-radius: 4px;color: #0077EE;" @click="openStation()">
                        {{stationList}}
                    </div>
                    <stationSelected v-if="showStationSelected && stationArray.length>0" @checkedStation='checkedStation' :showStationSelected='showStationSelected' :areaList='stationArray'></stationSelected>
                </FormItem>
                <FormItem class="formItemWidth" label="站点编号" prop="statsId">
                    <!-- <Input v-model="formData.stats" type="textarea" readonly/> -->
                    <div style="padding: 0 7px;min-height: 30px;width: 450px;border: 1px solid #0077EE;border-radius: 4px;color: #0077EE;" disabled>
                        {{formData.statsId}}
                    </div>
                </FormItem>
                <div class="typeAll ivu-form-item">
                    <FormItem v-if="formData.thrType === 1" prop="element" style="margin:0 10px 0 20px;">
                        <Select v-model="formData.element" style="width: 170px;" @on-change="changeElement(formData.element)">
                            <Option v-for="(item, index) in elementList" :key="index" :value="item.name">{{item.text}}</Option>
                        </Select>
                    </FormItem>
                    <FormItem v-else prop="element" style="margin:0 10px 0 20px;">
                        <Select v-model="formData.element" style="width: 170px;">
                            <Option v-for="(item, index) in elementList2" :key="index" :value="item.name">{{item.text}}</Option>
                        </Select>
                    </FormItem>
                    <FormItem prop="minValue" v-if="!hideMinInput">
                        <Input type="number" v-model="formData.minValue" style="width: 200px;"/>
                    </FormItem>
                    <div v-if="!hideMinInput" style="margin:0 24px;">-</div>
                    <FormItem prop="maxValue">
                        <Input type="number" v-model="formData.maxValue" style="width: 200px"/>
                    </FormItem>
                </div>
                <FormItem class="formItemWidth" label="预警内容">
                    <Input v-model="formData.content" type="textarea"/>
                </FormItem>
                <!-- <GeminiScrollbar style="height:48%;overflow: auto;"> -->
                <FormItem label="人员选择">
                    <!-- <Input v-model="formData.personIds" type="textarea" style="display:none;"/> -->
                    <div>
                        <memberSelect @checkedMembers='checkedMembers' v-if="areaList.length>0 && roleList.length>0" :roleList='roleList' :areaList='areaList'></memberSelect>
                    </div>
                </FormItem>
                <!-- </GeminiScrollbar> -->
                <FormItem label="是否生效">
                    <RadioGroup v-model="formData.state" style="color: #0077EE;">
                        <Radio label="1">是</Radio>
                        <Radio label="0">否</Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem>
                    <Button style="background: #00A0E9;" type="primary" @click="submit">保存</Button>
                </FormItem>
            </Form>
            </GeminiScrollbar> 
        </Content>
    </Layout>
</template>
<script>
import Vue from 'vue'
import request from '@/utils/request'
import stationSelect from './station_select.vue'
import stationSelected from './station_selected.vue'
import memberSelect from './member_select.vue'
import config from '@/utils/config'
const monthList = [
    { month: 1},
    { month: 2},
    { month: 3},
    { month: 4},
    { month: 5},
    { month: 6},
    { month: 7},
    { month: 8},
    { month: 9},
    { month: 10},
    { month: 11},
    { month: 12},
]
const tenDayList = [
    { tenDay: '上旬'},
    { tenDay: '中旬'},
    { tenDay: '下旬'},
]
export default Vue.extend({
    components: {
        stationSelect,
        stationSelected,
        memberSelect,
    },
    data() {
        return {
            formData: {
                state: '1',
                thrType: 1,
            },
            rules: {
                stationList: [{ required: true, message: '站点不能为空', trigger: 'blur' }],
                statsId: [{ required: true, message: '站点不能为空', trigger: 'blur' }],
                content: [{ required: true, message: '预警内容不能为空', trigger: 'blur' }],
            },
            elementList: '',
            elementList2: [{name: 'tem', text: '温度'}],
            stationList: '',
            areaList: [],
            roleList: [],
            stationArray: [],
            currentAdminCode: '',
            showStationSelected: false,
            hideMinInput: false,
            thrList: [{
                thrType: 1,
                text: '实况数据',
            }, {
                thrType: 2,
                text: '预报数据',
            }],
            monthList,
            tenDayList,
        }
    },
    mounted() {
        this.currentAdminCode  = this.$store.state.current.areaId
        this.getElementList()
        this.getAreaList()
        this.getPublishRole()
        this.getStationList()
        const d = new Date()
        this.formData.month = d.getMonth() + 1
        if (d.getDate() < 11) {
            this.formData.tenDay = '上旬'
        } else if (d.getDate() >= 11 &&  d.getDate() < 21) {
            this.formData.tenDay = '中旬'
        } else {
            this.formData.tenDay = '下旬'
        }
    },
    methods: {
        getElementList() {
            request.get('/api/respond/element/list', null)
              .then((data) => {
                this.elementList = data
                this.formData.element = this.elementList[0].name
                this.changeElement(this.formData.element)
              })
        },
        getAreaList() {
            return request.get('/api/sys/area/descendants', null)
            .then((data) => {
                const {current} = this.$store.state
                this.areaList = [...[{id: current.areaId, name: current.areaName}], ...data]
            })
        },
        getStationList() {
            const params = {
                staLevels: '011,012,013,014',
            }
            // if (!this.currentAdminCode || this.currentAdminCode === config.defaultStationObj.adminCode) {
            //     params = {
            //         staLevels: '011,012,013',
            //     }
            // }
            // console.log(params)
            // return request.get('/cw/station_info/list', params)
            // .then((data) => {
            //     this.stationArray = data
            // })
            return request.get('/cw/station_info/list', params)
            .then((data) => {
                if (!this.currentAdminCode || this.currentAdminCode === config.defaultStationObj.adminCode) {
                    this.cityList = data.filter((e) => {
                        return e.station_levl !== '14'
                    })
                    this.countyList = data.filter((e) => {
                        return e.station_levl === '14'
                    })
                } else {
                    let code = this.currentAdminCode
                    if (this.currentAdminCode === '610703') {
                        code = '610721'
                    }
                    this.cityList = data.filter((e) => {
                        return e.admin_code_chn === code && e.station_levl !== '14'
                    })
                    this.countyList = data.filter((e) => {
                        return e.admin_code_chn === code && e.station_levl === '14'
                    })
                }
                this.stationArray = [...this.cityList, ...this.countyList]
                // this.stationArray = this.countyList
            })

        },
        getPublishRole() {
            return request.get('/api/respond/respond_role/list', null)
            .then((data) => {
                this.roleList = data
            })
        },
        changethrType() {
            if (this.formData.thrType === 1) {
                this.formData.element = 'pre_1h'
            } else if (this.formData.thrType === 2) {
                this.formData.element = 'tem'
            }
        },
        submit() {
            const dateStr = this.calculateDate(this.formData.month, this.formData.tenDay).dateStr
            // const cron =  `* * * ${dateStr} ${this.formData.month} ?`
            const beginMonDay = Number(this.calculateDate(this.formData.month, this.formData.tenDay).beginMonDay)
            const endMonDay = Number(this.calculateDate(this.formData.month, this.formData.tenDay).endMonDay)
            // console.log(this.formData)
            // this.formData.stats
            const params = {...this.formData, beginMonDay, endMonDay}
            // console.log(params)
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                request.post('/api/respond/threshold', params)
                .then((res) => {
                    this.$Message.success('保存成功!')
                    this.$router.replace('/sys_manage/threshold')
                }).catch((error) => {
                    this.$Message.warning(error)
                })
                }
            })
        },
        openStation() {
            this.showStationSelected = true
        },
        checkedStation(value) {
            const {checked, status} = value
            this.showStationSelected = false
            if (status === 'ok') {
                this.formData.statsId = checked.map((e) => e.station_id_c).join('，')
                this.formData.stats = checked.map((e) => e.station_id_c).join(',')
                this.stationList = checked.map((e) => e.station_name).join('，')
            }
        },
        checkedMembers(value) {
            this.formData.personIds = value.map((e) => e.id)
        },
        changeElement(element) {
            if (element.indexOf('pre') > -1) {
                this.hideMinInput = true
            } else {
                this.hideMinInput = false
            }
        },
        calculateDate(month, period) {
            let dateStr = ''
            let beginMonDay = ''
            let endMonDay = ''
            if (period === '上旬') {
                dateStr = '01-10'
                beginMonDay = `${month}01`
                endMonDay = `${month}10`
            }
            if (period === '中旬') {
                dateStr = '11-20'
                beginMonDay = `${month}11`
                endMonDay = `${month}20`
            }
            if (period === '下旬') {
                dateStr = this.calculatePeriodDay(month)
                beginMonDay = `${month}21`
                endMonDay = `${month}${this.calculatePeriodDay(month).day}`
            }
            return {dateStr, beginMonDay, endMonDay}
        },
        calculatePeriodDay(month) {
            let dateStr = ''
            let day = ''
            const arr1 = ['1', '3', '5', '7', '8', '10', '12']
            const arr2 = ['4', '6', '9', '11']
            arr1.forEach((e) => { if (month === e) { dateStr = '21-31' } })
            arr2.forEach((e) => { if (month === e) { dateStr = '21-30' } })
            arr1.forEach((e) => { if (month === e) { day = '31' } })
            arr2.forEach((e) => { if (month === e) { day = '30' } })
            if (month === '2') {
                const date = new Date()
                const year = date.getFullYear()
                const  flag = year % 400 === 0 || (year % 4 === 0 && year % 100 !== 0)
                if (flag) {
                    dateStr = '21-29'
                    day = '29'
                } else {
                    dateStr = '21-28'
                    day = '28'
                }
            }
            return {dateStr, day}
        },
    },
})
</script>
<style scoped>
.formItemWidth {
    width: 650px;
}
.typeAll{
    display: flex;
}
.poptipContent{
    display: flex;
    width: 100%;
}
  .ivu-layout {
    background: transparent;
    height: 100%;
  }
  .header{
    height: 40px;
    line-height: 40px;
    background: transparent;
  }
  ::v-deep .ivu-breadcrumb{
    font-size: 16px;
  }
  ::v-deep .ivu-breadcrumb a{
    color: #0077EE;
  }
  ::v-deep .ivu-breadcrumb-item-separator{
    color: #0077EE;
  }
  ::v-deep .ivu-breadcrumb>span:last-child{
    color: #0077EE;
  }
  ::v-deep .ivu-form .ivu-form-item-label{
   color: #0077EE;
 }
 
.content {
    display: flex;
    justify-content: center;
    /* background-color: red; */
    height: calc(100% - 40px);
}
 ::v-deep .ivu-input{
   background-color: transparent;
   color: #0077EE;
   border-color: #0077EE;
 }
 ::v-deep .ivu-input-number{
   background-color: transparent;
   border-color: #0077EE;
 }
 ::v-deep .ivu-input-number-input{
   background-color: transparent;
   color: #0077EE;
 }
 ::v-deep .ivu-select-selection{
   background-color: transparent;
   border-color: #0077EE;
   color: #0077EE;
 }
 ::v-deep .ivu-spin-fix{
   background-color: transparent;
   border-color: #0077EE;
 }
 ::v-deep .ivu-table-wrapper>.ivu-spin-fix{
   border: none;
 }
</style>