





import Vue from 'vue'
import request from '@/utils/request'
export default Vue.extend({
    props: {
        stationIds: {
            type: String,
        },
        areaList: {
            type: Array,
        },
    },
    watch: {
        stationIds() {
            this.getData()
        },
    },
    data() {
        return {
            baseData: [{}],
            currentId: undefined,
            formData: {},
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            const data = this.areaList
            const result: any[] = []
            data.forEach((element: any) => {
                const {station_name, station_id_c, admin_code_chn} = element
                if (station_id_c === '57123') {
                    result.push({
                        id: station_id_c,
                        name: station_name,
                        parentId: null,
                    })
                } else {
                    result.push({
                        id: station_id_c,
                        name: station_name,
                        parentId: '57123',
                    })
                }
            })
            this.baseData = this.buildTreeNodes(result)
        },
        buildTreeNodes(data: any): any[] {
            if (!data || data.size === 0) {
                return []
            }
            const array: any[] = []
            const nodeMap: any = {}
            data.forEach((e: any) => {
                const { id, parentId } = e
                const node = this.buildNode(e)
                nodeMap[id] = node
                if (nodeMap[parentId]) {
                    nodeMap[parentId].children.push(node)
                } else {
                    array.push(node)
                }
            })
            return array
        },
        buildNode(geoData: any) {
            const { id, parentId, name, lat, lon } = geoData
            let node = {id, title: `${name}`, name, lat, lon, parentId, checked: false}
            if (this.stationIds) {
                const stationIdList = this.stationIds.split(',')
                if (stationIdList.indexOf(id) > -1) {
                    node = {id, title: `${name}`, name, lat, lon, parentId, checked: true}
                }
            }
            if (id !== '57123') {
                return node
            }
            if (id === '57123') {
                const nodeNew = {...node, expand: true, children: []}
                return nodeNew
            }
            return node
        },
        onSelect(checkedNode: any[], selectedNode: any) {
            this.$emit('checkedStation', checkedNode)
        },
    },
})
