<template>
    <div>
        <Modal v-model="modal1" width='600' title="选择站点"  @on-ok="okModal" @on-cancel="cancelModal">
            <!-- <div style="border-bottom: 1px solid #e9e9e9;padding-bottom:6px;margin-bottom:6px;">
                <Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="handleCheckAll">全选</Checkbox>
            </div> -->
            <div class="classify">
                <GeminiScrollbar>
                    <!-- <CheckboxGroup v-model="selectAreaCheckBox" @on-change="checkAreaGroupChange"> -->
                        <div class="classifyStation">
                            <div class="labelTop">
                                <Checkbox
                                    :indeterminate="indeterminate"
                                    :value="checkAll"
                                    @click.prevent.native="handleCheckAll">全选</Checkbox>
                            </div>
                            <div>
                                <CheckboxGroup v-model="checkAllGroup"  @on-change="checked=>checkAllGroupChange(checked)">
                                    <Checkbox  v-for="(areaStation, areaIndex) in allList" :key="areaIndex" :label="areaStation.station_id_c">
                                        <span>{{areaStation.station_name}}</span>
                                    </Checkbox>
                                </CheckboxGroup>
                            </div>
                        </div>
                    <!-- </CheckboxGroup> -->
                </GeminiScrollbar>
            </div>
        </Modal>
    </div>
</template>
<script>
import Vue from 'vue'
import config from '@/utils/config'
export default Vue.extend({
    props: {
        stationIds: {
            type: String,
        },
        areaList: {
            type: Array,
        },
    },
    data() {
        return {
            modal1: true,
            allList: [],
            indeterminate: false,
            checkAll: false,
            checkAllGroup: [],
            currentAdminCode: '',
        }
    },
    mounted() {
        this.currentAdminCode  = this.$store.state.current.areaId
        this.handleData()
        if (!this.stationIds && this.stationIds === undefined) {
            // console.log('不存在')
        } else {
            // console.log('存在')
            // console.log(this.stationIds)
            const arr = this.stationIds.split(',')
            this.checkAllGroup = arr
        }
    },
    methods: {
        handleData() {
            let cityList = []
            let countyList = []
            if (!this.currentAdminCode || this.currentAdminCode === config.defaultStationObj.adminCode) {
                cityList = this.areaList.filter((e) => {
                    // return e.station_levl !== '14'
                    return e.admin_code_chn === config.defaultStationObj.adminCode
                })
                countyList = []
            } else {
                cityList = this.areaList.filter((e) => {
                    return e.admin_code_chn === this.currentAdminCode && e.station_levl !== '14'
                })
                countyList = this.areaList.filter((e) => {
                    return e.admin_code_chn === this.currentAdminCode && e.station_levl === '14'
                })
            }
            this.allList = [...cityList, ...countyList]
        },
        getTownByCounty() {
            // console.log(this.allList)
            const allTown = this.allList.map((e) => {
                return e.station_id_c
            })
            return allTown
        },
        handleCheckAll() {
            if (this.indeterminate) {
                this.checkAll = false;
            } else {
                this.checkAll = !this.checkAll;
            }
            this.indeterminate = false;

            if (this.checkAll) {
                this.checkAllGroup = this.getTownByCounty();
            } else {
                this.checkAllGroup = [];
            }
        },
        checkAllGroupChange(data) {
            if (data.length === this.allList.length) {
                this.indeterminate = false;
                this.checkAll = true;
            } else if (data.length > 0) {
                this.indeterminate = true;
                this.checkAll = false;
            } else {
                this.indeterminate = false;
                this.checkAll = false;
            }
        },
        getCheckStation(status) {
            const checked = this.allList.filter((e) => {
                if (this.checkAllGroup.includes(e.station_id_c)) {
                    return e
                }
            })
            this.$emit('checkedStation', {status, checked})
        },
        okModal() {
            this.getCheckStation('ok')
        },
        cancelModal() {
            this.getCheckStation('cancle')
        },
    },
})
</script>
<style scoped>
    .classify{
        height: 500px;
        overflow: hidden;
    }
</style>